import { baseUrl, baseApiUrl } from '../helper'

/**
 * Retries each set of fetch parameters until one returns a response in the 2xx range.
 * Throws an error if no fetch succeeded.
 * @param params An array of fetch parameter tuples.
 */
export async function retryFetch(params: Array<[RequestInfo | URL, RequestInit?]>): Promise<Response> {
  for (const [index, [input, init]] of params.entries()) {
    try {
      const response = await fetch(input, init)
      if (!response?.ok) throw new Error(`response not 2xx, actually ${response?.status}`)
      return response
    } catch (error) {
      console.warn(`retryFetch fell back due to "${error}" on try ${index + 1} of ${params.length} for ${input}`)
    }
  }

  throw new Error('all fetches failed')
}

/**
 * Fetches with a specified timeout in seconds.
 * @param seconds How long to limit the request before returning null, in seconds.
 * @param input Fetch input.
 * @param init Fetch init.
 */
export async function fetchWithTimeout(
  seconds: number,
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<null | Response> {
  return await new Promise((resolve) => {
    const timeout = setTimeout(() => resolve(null), seconds * 1000)
    fetch(input, init)
      .then((response) => {
        clearTimeout(timeout)
        resolve(response)
      })
      .catch(() => resolve(null))
  })
}

export async function apiVercelFallbackRaw(url: string, init?: RequestInit): Promise<null | Response> {
  url = url.startsWith('/') ? url : '/' + url
  const requestInit: RequestInit = {
    credentials: 'include',
    ...(init || {}), // Adding init here so header doesn't get overwritten by what's passed.
    headers: {
      'Content-Type': 'application/json',
      ...(init?.headers || {})
    }
  }

  // Vercel by default.
  let base = baseUrl + '/api'

  // Hard override to fix slowdowns.
  if (baseUrl !== 'https://app.caseopp.com') {
    // Check /status of the API server, set base to point to the API server if successful.
    if (await fetchWithTimeout(1, baseApiUrl + '/status')) {
      base = baseApiUrl
    }
    if (base.includes('api.') && url.includes('/api')) {
      url.replace('/api', '')
    }
  }
  return await fetch(base + url, requestInit)
}

/**
 * Retries an endpoint based on `BASE_URL` and `BASE_API_URL` env variables.
 * - Parses JSON responses.
 * - Includes credentials.
 * @param url Endpoint to append to different base URLs.
 * @param init The request init, optional raw field to return response as-is.
 */
export async function apiVercelFallback<T = any>(url: string, init?: RequestInit): Promise<null | T> {
  // Convert the JSON.
  return (await apiVercelFallbackRaw(url, init)
    .then(async (response) => await response?.json().catch(() => null))
    .catch(() => null)) as T
}
