/**
 * Enum for the different types of questions and answer types
 */
export enum QUESTION_TYPE {
  BOOL = 'Boolean',
  DATE = 'Date',
  DATETIME = 'DateTime',
  NO_ANSWER = 'No Answer',
  NUMBER = 'Number',
  REPEATING_QUESTION = 'Repeating Question',
  SECRET = 'Secret',
  SELECT_MANY = 'Select Many',
  SELECT_ONE = 'Select One',
  TEXT = 'Text Field'
}

/**
 * Enum for the different types of transaction types
 */
export enum TransactionType {
  CaseCost = 'Case Cost',
  ClientDisbursement = 'Client Disbursement',
  Deposit = 'Deposit',
  Expense = 'Expense',
  ExpenseReimbursement = 'Expense Reimbursement',
  FirmDisbursement = 'Firm Disbursement',
  Incoming = 'incoming (legacy)',
  Loan = 'Loan',
  Outgoing = 'outgoing (legacy)',
  SettlementDisbursement = 'Settlement Disbursement'
}
