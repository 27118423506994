/* eslint-disable @next/next/no-img-element */
import { useGlobal, useState, useEffect } from 'reactn'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
// MUI Core
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'

const thisFile = 'Loading ' // eslint-disable-line no-unused-vars

export const LoadingAnimation = (props) => {
  const { loadingStack, isLoading, message } = props
  const theme = useTheme()
  // src, width, height, top, text margin-top
  const justice = ['/loadingGif/caseopp_loading.gif', 300, 300, 175]
  // let animations

  // const octoberAnimations = [
  //   ['/loadingGif/10/frankenstein-walking-animated.gif', 96, 154, 210],
  //   ['/loadingGif/10/halloween-skeleton-3.gif', 80, 133, 240],
  //   ['/loadingGif/10/three-jack-o-lanterns.gif', 319, 165, 250],
  //   ['/loadingGif/10/crazy-witch.gif', 180, 194, 250, 20],
  //   ['/loadingGif/10/dancing-jack-o-lantern.gif', 148, 139, 270]
  // ]
  // const novemberAnimations = [
  //   ['/loadingGif/11/2018-best-friends-animation.gif', 100, 154, 360],
  //   ['/loadingGif/11/2018-ready-to-eat-turkey-animation.gif', 127, 91, 310],
  //   ['/loadingGif/11/2019-turkey-christmas-shopping-black-friday.gif', 200, 178, 290],
  //   ['/loadingGif/11/2019-turkey-pilgrim-animation.gif', 150, 150, 290],
  //   ['/loadingGif/11/animated-fall-leaves.gif', 110, 116, 310],
  //   ['/loadingGif/11/fast-dancing-turkey.gif', 52, 48, 350],
  //   ['/loadingGif/11/runningFootballTurkey.gif', 350, 316, 280]
  // ]

  const [showTimeout, setShowTimeout] = useState()
  const [loadingSeconds, setLoadingSeconds] = useState(0)

  // switch (moment(new Date()).month() + 1) {
  //   case 10:
  //     animations = octoberAnimations
  //     break
  //   case 11:
  //     animations = novemberAnimations
  //     break
  //   case 12:
  //     animations = [
  //       justice
  //     ]
  //     break
  //   default:
  //     animations = [justice]
  // }

  // const randomAnimationIndex = getRandomIntInclusive(0, (animations.length - 1))
  // let dayIndex // Prevent auto-fix
  // eslint-disable-next-line prefer-const
  // dayIndex = moment(new Date()).date() % animations.length // For REAL

  // default justice
  const animation = justice // For REAL
  // If NOT in this list, animate from list
  // if (clientWindow) {
  //   if (!clientWindow.location.href.includes('questionnaire')) {
  //     animation = animations[dayIndex] // Override
  //   }
  // }

  // For testing
  // animations = novemberAnimations // Override what animations
  // dayIndex = 0 % animations.length // cycle through with App user === user to test AND default from justice BELOW
  // animation = animations[dayIndex] // For using App to cycle

  const zIndex = {
    loading: 99990,
    accordion: 99992,
    overlay: 99980
  }
  const backgroundColor = 'rgba(0,0,0,0.4)'
  const accordionBackgroundColor = 'rgba(255,255,255,1)'

  const loadingTimeoutMilliseconds = 25000

  useEffect(() => {
    let mounted = true
    setTimeout(() => {
      mounted && setShowTimeout(true)
    }, loadingTimeoutMilliseconds)
    const interval = setInterval(() => {
      mounted && setLoadingSeconds((prev) => Math.min(50, ++prev))
    }, 1000)
    return () => {
      mounted = false
      clearInterval(interval)
    }
  }, [])

  const loadingStars = Array(loadingSeconds + 1).fill(loadingSeconds > 10 ? (loadingSeconds > 20 ? '!' : '#') : '*')
  return (
    <>
      <div id='loading' className='qa-loading' data-testid='qa-loading'>
        <img id='loadingImage' src={animation[0]} alt='Loading...' />
        <h4 id='loadingMessage'> {loadingStars.join(' ') + ' ' + message + ' ' + loadingStars.join(' ')} </h4>
        {showTimeout && (
          <div id='accordionDiv'>
            <Accordion style={{ backgroundColor: accordionBackgroundColor }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
                <Typography
                  style={{
                    fontSize: theme.typography.pxToRem(15),
                    fontWeight: theme.typography.fontWeightRegular
                  }}
                >
                  Expand to Debug Stuck on Loading Issues:
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div id='loading-debug'>
                  <span>Is Loading: {JSON.stringify(isLoading)}</span>
                  <br />
                  <span>Loading Stack: {JSON.stringify(loadingStack)}</span>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
      <style jsx>
        {`
          #loading {
            position: fixed;
            z-index: ${zIndex.loading};
            width: ${animation[1]}px;
            overflow: visible;
            margin-left: auto;
            margin-right: auto;
            top: ${animation[3]}px;
            left: 0;
            bottom: 0;
            right: 0;
          }
          #accordionDiv {
            z-index: ${zIndex.accordion};
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: ${410}px;
            min-width: 75px;
            margin-top: ${50}px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }
          #loading img {
          }

          #loading-debug {
            width: ${animation[1]}px;
            min-width: 75px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }

          #loading h4 {
            position: inherit;
            width: ${animation[1]}px;
            min-width: 75px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
          }

          /* Transparent Overlay */
          #loading:after {
            z-index: ${zIndex.overlay};
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${backgroundColor};
          }
        `}
      </style>
    </>
  )
}

LoadingAnimation.propTypes = {
  loadingStack: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
}

const Loading = () => {
  const [loadingStack] = useGlobal('loadingStack')
  const [isLoading] = useGlobal('isLoading')
  const [message] = useGlobal('loadingMessage')

  return (
    <>
      {isLoading && (
        <LoadingAnimation
          {...{
            loadingStack,
            isLoading,
            message
          }}
        />
      )}
      {!isLoading && <div className='qa-loaded' hidden />}
    </>
  )
}

export default Loading
