import { useEffect } from 'react'
import { useGlobal, getGlobal, setGlobal } from 'reactn'
import { consoleError } from '../helpers/helper'
const thisFile = 'loadingHook ' // eslint-disable-line no-unused-vars

/**
 * Custom React Hook for the Loading Graphic
 */
function useLoading() {
  const [loadingStack] = useGlobal('loadingStack')

  const [isLoading, setIsLoading] = useGlobal('isLoading')
  // If there are any processes on the loading stack then set isLoading to true
  useEffect(() => {
    if (loadingStack?.length >= 0) {
      if (loadingStack.length > 0) {
        !isLoading && setIsLoading(true)
        // console.log('loading stack IsLoading(true): ' + loadingStack)
      } else {
        isLoading && setIsLoading(false)
        // console.log('loading stack IsLoading(false): ' + loadingStack)
      }
    }
  }, [loadingStack, setIsLoading, isLoading])

  /**
   * Pushes key of process onto the loadingStack Array
   * @param {string} [hash] - unique key of process
   */
  const loadingStackPush = async (hash) => {
    try {
      return new Promise((resolve, reject) => {
        try {
          // consoleDev(thisFile, 'loadingStackPush hash:', hash)
          const stack = getGlobal().loadingStack || []
          const newStack = [...stack] // Shallow copy.  Use deepClone otherwise.
          if (newStack.indexOf(hash) < 0) newStack.push(hash)
          setGlobal({ loadingStack: newStack }, () => {
            resolve(newStack)
          })
        } catch (error) {
          reject(new Error(consoleError(thisFile, 'loadingStackPush error:', error.message)))
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  /**
   * Removes a process from the loadingStack Array
   * @param {string} [hash] - unique key of process
   */
  const loadingStackRemove = async (hash) => {
    try {
      return new Promise((resolve, reject) => {
        try {
          const stack = getGlobal().loadingStack || []
          if (hash !== 'ALL') {
            const index = stack.indexOf(hash)
            if (index !== -1) {
              const newStack = [...stack]
              newStack.splice(index, 1)
              setGlobal({ loadingStack: newStack }, () => {
                resolve(newStack)
              })
            }
          } else {
            setGlobal({ loadingStack: [] }, () => {
              resolve([])
            })
          }
        } catch (error) {
          reject(new Error(consoleError(thisFile, 'loadingStackRemove error:', error.message)))
        }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  return {
    loadingStackPush,
    loadingStackRemove,
    isLoading
  }
}

export default useLoading
