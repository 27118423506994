import { datadogLogs } from '@datadog/browser-logs'
import { ConsolaTransport } from '@loglayer/transport-consola'
import { DataDogTransport } from '@loglayer/transport-datadog'
import { DataDogBrowserLogsTransport } from '@loglayer/transport-datadog-browser-logs'
import { createConsola } from 'consola'
import { LogLayer, type ILogLayer } from 'loglayer'
import { baseUrl } from './helpers/helper'

// Determine if we are on the server or client so we can use the correct loggers
const isServer = typeof window === 'undefined'
const debug = process.env.DEBUG === 'true'

// Initialize Datadog if production
if (process.env.ENV === 'production') {
  datadogLogs.init({
    clientToken: process.env.DATADOG_BROWSER_LOG_TOKEN ?? '',
    site: 'datadoghq.com',
    silentMultipleInit: true,
    service: 'caseopp',
    env: process.env.ENV ?? 'development',
    forwardConsoleLogs: debug ? 'all' : ['info', 'warn', 'error']
  })
}

const datadogLogger = datadogLogs.createLogger('CaseOpp')

const logger = new LogLayer({
  errorSerializer: (error) => ({
    message: error.message,
    stack: error.stack,
    kind: error.name
  }),
  transport: [
    new ConsolaTransport({
      enabled: process.env.ENV !== 'production' || !isServer,
      logger: createConsola({
        level: debug ? 5 : 3
      })
    }),
    new DataDogBrowserLogsTransport({
      enabled: process.env.ENV === 'production' && !isServer,
      logger: datadogLogger
    }),
    new DataDogTransport({
      options: {
        ddClientConf: {
          authMethods: {
            apiKeyAuth: process.env.DATADOG_API_KEY ?? ''
          }
        },
        ddsource: 'server',
        service: 'caseopp',
        ddtags: `env:${process.env.NODE_ENV || 'development'}`
      },
      level: debug ? 'debug' : 'info',
      enabled: process.env.ENV === 'production' && isServer
    })
  ]
})

/**
 * Returns the configured LogLayer instance that handles logging across both client and server environments.
 * The logger is pre-configured with console, Datadog browser, and Datadog server transports based on the environment.
 * @param file - The file name of the file that is calling the logger.
 * @returns The configured LogLayer instance
 *
 * @docs
 * https://loglayer.dev/getting-started.html
 *
 * @note
 * We use a function to get the logger so we can use this in tests:
 * https://loglayer.dev/logging-api/unit-testing.html
 *
 * @example
 * const logger = getLogger('index.js')
 * logger
 *   .withContext({
 *     function: 'myFunction',
 *   })
 *   .withMetadata({
 *     userId: '123',
 *     userName: 'John Doe'
 *   })
 *   .withError(new Error('test'))
 *   .error('This is an error')
 */
export function getLogger(file?: string): ILogLayer {
  // clear the context so we can start fresh
  const clearedLogger = logger.clearContext()
  // return the logger with the context
  return clearedLogger.withContext({ hostname: baseUrl, ...(file ? { file } : {}) })
}
